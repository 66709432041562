/**
 * Configure the router.
 *
 * /!\ You'll have to restart your local dev server after any change to this file.
 */

const routes = require('next-routes');

const pages = routes()
  .add('home', '/(thuat-ngu)?', 'index')
  .add('widget', '/widget/thuatngu', 'widget')
  .add('detailRoute', '/:slug?', 'detail');
module.exports = pages;
