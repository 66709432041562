import React, { useState, useRef } from 'react';
import { ChevronDown, Check } from 'react-feather';
import useOutsideClick from './useOutsideClick';
import { isEmpty } from '../../utils/utils';

import useDeviceDetect from "../../utils/useDeviceDetect";

function CatABS(props) {
  const { hl, cats, catId, onLanguageChange, onCategoryChange } = props;

  const [showCat, setShowCat] = useState(false);

  const ref = useRef();

  const { isMobile } = useDeviceDetect();

  useOutsideClick(ref, () => {
    if (showCat) setShowCat(false);
  });
  let catTitle = 'Tất cả';
  if (!isEmpty(catId)) {
    const currentCat = cats.find(x => x.id === parseInt(catId, 10));
    if (!isEmpty(currentCat)) {
      catTitle = currentCat.title;
    }
  }

  const catLeft = [];
  const catRight = [];
  const maxItemsInOddArray = Math.floor(cats.length / 2);
  cats.forEach(cat => {
    if (catLeft.length < maxItemsInOddArray) {
      catLeft.push(cat);
    } else {
      catRight.push(cat);
    }
  });

  const catsMobile = [];
  if (isMobile) {
    for (let i = 0; i < catRight.length; i++) {
      if (!isEmpty(catRight[i])) {
        catsMobile.push(catRight[i]);
      }
      if (!isEmpty(catLeft[i])) {
        catsMobile.push(catLeft[i]);
      }
    }
  }

  const catData = isMobile ? catsMobile : cats;

  return (
    <>
      <div className="abs">
        <div className="abs__item">
          <div className="abs__language">
            <span className="la">Chọn ngôn ngữ :</span>
            <span className={`chosen ${hl === 'vn' ? 'is-active' : ''}`} onClick={() => onLanguageChange('vi')}>
              Tiếng Việt
            </span>
            <span className={`chosen ${hl === 'en' ? 'is-active' : ''}`} onClick={() => onLanguageChange('en')}>
              Tiếng Anh
            </span>
          </div>
        </div>
        <div className="abs__item">
          <div className="abs__cat__container">
            <span className="la">Lĩnh vực :</span>
            <span className="link font-weight-bold abs__cat" onClick={() => setShowCat(!showCat)}>
              {catTitle} <ChevronDown />
            </span>
            {showCat && (
              <div className="abs__cat__drodown" ref={ref}>
                <ul className="abs__cat__list list-unstyled">
                  <li>
                    <div
                      className={`link ${parseInt(catId, 10) === 0 ? 'is-active' : ''}`}
                      onClick={() => onCategoryChange(0)}
                    >
                      <span className="abs__cat__check">
                        <Check />
                      </span>
                      Tất cả
                    </div>
                  </li>
                  {catData &&
                    catData.length > 0 &&
                    catData.map((item, index) => (
                      <li key={item.id}>
                        <div
                          className={`link ${parseInt(catId, 10) === item.id ? 'is-active' : ''}`}
                          onClick={() => onCategoryChange(item.id)}
                        >
                          <span className="abs__cat__check">
                            <Check />
                          </span>
                          {item.title}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CatABS;
