import SearchTerm from './thuatngu/SearchTerm';
import { Link, Router } from '../server/pages';
import CatTerm from './thuatngu/CatTerm';
import CatABS from './thuatngu/CatABS';
import React from 'react';
import { isEmpty } from '../utils/utils';
import { useRouter } from 'next/router';
import * as queryString from 'query-string';
import { ALPHABET_EN, ALPHABET_VI } from '../constants/constants';
import { ChevronDown } from 'react-feather';

let ALPHABET = [];

function Search(props) {
  const router = useRouter();
  const { alphabet, catId, cats, hl, text, setText, setAlphabet, setLanguage, setCategory } = props;

  if (!isEmpty(hl)) {
    if (hl === 'vn') {
      ALPHABET = ALPHABET_VI;
    } else {
      ALPHABET = ALPHABET_EN;
    }
  }

  const handleLanguageChange = language => {
    setLanguage(language);
    setText(text);
    setCategory(catId);
    setAlphabet(alphabet);
    const qs = queryString.stringify({
      ...(!isEmpty(alphabet) && { alphabet }),
      ...(language === 'en' && { hl: language }),
      ...(!isEmpty(catId) && { catId }),
      page: 1,
    });
    router.push({
      pathname: '/thuat-ngu',
      query: qs,
    });
  };

  const handleAlphabetChange = async value => {
    let currentAlphabet = value;
    if (value === alphabet) {
      await setAlphabet(null);
      currentAlphabet = null;
    } else {
      await setAlphabet(value);
    }

    setText(null);
    const qs = queryString.stringify({
      ...(!isEmpty(currentAlphabet) && { alphabet: currentAlphabet }),
      ...(hl === 'en' && { hl }),
      ...(!isEmpty(catId) && { catId }),
      page: 1,
    });
    router.push({
      pathname: '/thuat-ngu',
      query: qs,
    });
  };

  const handleCategoryChange = async value => {
    let currentCategory = value;
    if (value.toString() === catId) {
      await setCategory(null);
      currentCategory = null;
    } else {
      await setCategory(value);
    }
    setText(null);

    const qs = queryString.stringify({
      ...(!isEmpty(alphabet) && { alphabet }),
      ...(!isEmpty(currentCategory) && currentCategory !== 0 && { catId: currentCategory }),
      ...(hl === 'en' && { hl }),
      page: 1,
    });
    router.push({
      pathname: '/thuat-ngu',
      query: qs,
    });
  };
  return (
    <div className="term__hero text-center">
      <div className="container">
        <div>
          <SearchTerm hl={hl} text={text} />
          <CatABS
            hl={hl}
            catId={!isEmpty(catId) ? catId: 0}
            cats={cats}
            onLanguageChange={handleLanguageChange}
            onCategoryChange={handleCategoryChange}
          />
          <div className="mt-0">
            <div className="term__alphabet">
              {ALPHABET.map(item => {
                return (
                  <div
                    className={`term__alphabet__item ${alphabet === item ? 'is-active' : ''}`}
                    key={item}
                    onClick={() => handleAlphabetChange(item)}
                  >
                    <Link route="withoutText" params={{ hl: hl, alphabet: item, page: 1 }}>
                      {item}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Search;
