/* eslint-disable */

import React from 'react';

import { debounce } from 'lodash';

import { Spinner } from 'react-bootstrap';

import { ChevronRight, ChevronLeft } from 'react-feather';

class CatTerm extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      hasOverflow: false,
      canScrollLeft: false,
      canScrollRight: false,
    };

    this.checkForOverflow = this.checkForOverflow.bind(this);
    this.checkForScrollPosition = this.checkForScrollPosition.bind(this);

    this.debounceCheckForOverflow = debounce(this.checkForOverflow, 1000);
    this.debounceCheckForScrollPosition = debounce(this.checkForScrollPosition, 200);

    this.container = null;
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.debounceCheckForScrollPosition);
    this.debounceCheckForOverflow.cancel();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.items.length !== this.state.items.length) {
      this.checkForOverflow();
      this.checkForScrollPosition();
    }
  }

  checkForScrollPosition() {
    const { scrollLeft, scrollWidth, clientWidth } = this.container;
 
    const scrollPixel = scrollWidth - clientWidth;
    this.setState({
      canScrollLeft: scrollLeft > 0,
      canScrollRight: scrollPixel - 5 <= scrollLeft || scrollLeft >= scrollPixel + 5,
    });

  }

  checkForOverflow() {
    const { scrollWidth, clientWidth } = this.container;
    const hasOverflow = scrollWidth > clientWidth;
    this.setState({ hasOverflow });
  }

  scrollContainerBy(distance) {
    this.container.scrollBy({ left: distance, behavior: 'smooth' });
  }

  buildControls() {
    const { canScrollLeft, canScrollRight } = this.state;
    return (
      <div className="cat__controls">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary rounded-circle cat__controls__item left"
          disabled={!canScrollLeft}
          onClick={() => {
            this.scrollContainerBy(-250);
          }}
        >
          <ChevronLeft />
        </button>

        <button
          type="button"
          className="btn btn-sm btn-outline-primary rounded-circle cat__controls__item right"
          disabled={canScrollRight}
          onClick={() => {
            this.scrollContainerBy(250);
          }}
        >
          <ChevronRight />
        </button>
      </div>
    );
  }

  componentDidMount() {
    fetch('https://apitn.vietnewscorp.vn/category')
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            items: result,
          });
          this.checkForOverflow();
          this.checkForScrollPosition();
          this.container.addEventListener('scroll', this.debounceCheckForScrollPosition);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { catId, onCategoryChange } = this.props;
    const { error, isLoaded, items } = this.state;
    const catLength = this.state.items.length;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className="term__scroll d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="dark" className="opacity-20" />
        </div>
      );
    } else {
      return (
        <>
          <div
            className="term__scroll"
            ref={node => {
              this.container = node;
            }}
          >
            <div className="term__alphabet term__cat">
              {items.map(item => (
                <div
                  className={`term__alphabet__item ${parseInt(catId, 10) === item.id ? 'is-active' : ''}`}
                  key={item.id}
                >
                  <span className="link" onClick={() => onCategoryChange(item.id)}>
                    {' '}
                    {item.title}{' '}
                  </span>
                </div>
              ))}
            </div>

        
          </div>

          {this.buildControls()}
        </>
      );
    }
  }
}

export default CatTerm;
