import React from 'react';
import { FileText } from 'react-feather';
import Link from "next/link";

function TopTerm(props) {
  const { hl, terms } = props;
  return (
    <>
      <div className="popular__wrap ">
        <div className="popular ">
          <div className="cluster__wrap ">
            <div className="cluster__heading is__dash__left">
              <h2 className="cluster__title text-uppercase"> Top thuật ngữ thông dụng</h2>
            </div>

            <div className="cluster__content pt-2 pb-2">
              <div className=" glossary-widget ">
                {terms &&
                  terms.length > 0 &&
                  terms.map(item => {
                    const { title, titleEn, slug, slugEn, id } = item || {};
                    let detailLink = `${slug}-${id}`;
                    if (hl === 'en') {
                      detailLink = `${slugEn}-${id}?hl=${hl}`;
                    }
                    return (
                      <div className="name" key={title}>
                        <Link href={detailLink}>
                          <a>
                            <FileText /> {hl === 'vn' ? title : titleEn}
                          </a>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopTerm;
