export const commonConst = {
  defaultLang: 'vn',
  defaultAlphabet: null,
  defaultText: null,
  defaultCategory: null,
  defaultActivePage: 1,
  defaultPageSize: 15,
  defaultGlobalLoadingTitle: 'Đang xử lý ...',
};

export const FACEBOOK_APP_ID = 3561603063851481;
export const ALPHABET_EN = [
  '0-9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const ALPHABET_VI = [
  '0-9',
  'A',
  'Ă',
  'Â',
  'B',
  'C',
  'D',
  'Ð',
  'E',
  'Ê',
  'G',
  'H',
  'I',
  'K',
  'L',
  'M',
  'N',
  'O',
  'Ô',
  'Ơ',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'Ư',
  'V',
  'X',
  'Y',
];
